"use client";

import React, { useEffect, useState, Suspense, } from "react";
import { getRootAccounts, getGroups, updateAccountGroup, importAccounts, createNewAccount, getInvite, updateInvite, resendText, updateProvAccount } from "../../api/shared";
import { 
  Pane, 
  Table, 
  Switch, 
  SideSheet, 
  Heading, 
  Paragraph, 
  Button, 
  toaster, 
  majorScale, 
  Pagination, 
  SelectMenu, 
  Position,
  Dialog,
  FileUploader,
  FileCard,
  TextInput,
  Combobox,
  TextareaField,
  Checkbox,
  Overlay,
  Spinner,
  Portal,
  TimelineLineChartIcon,
  ListItem,
  UnorderedList,
} from "evergreen-ui";
import errorHandler from "../errorMessages/errorHandler";
import "./styles.css";
import LoadingWord from "../loader/loadingWord";
import StatsDialog from './statsDialog';
import { View, Text, TouchableOpacity, Modal, FlatList } from 'react-native';
import CountryPicker from "./CountryPicker";
// import CountryPicker, { Country, CountryCode } from 'react-native-country-picker-modal';


//This is the interface for the RootAccount that will serve as a template to be followed later in the code
interface RootAccount {
  id: string;
  accountId: string;
  group: string;
  enabled: boolean;
  deviceOs: string;
  coraV: string;
  installed: string;
  status: string;
}

//This is for the file uploader
interface FileObject {
  name: string;
  size: number;
  type: string;
}

//This is for the file rejection
interface FileRejection {
  file: FileObject;
  message: string;
}

interface GroupOption {
  label: string;
  value: string;
};

type Country = {
  countryCode: string;
  countryName: string;
  dialCode: string;
  flag: string;
}

//This is the logic that will be used to upload a single file (ie. a CSV file for creating new accounts)
const FileUploaderSingleUpload: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onImport: (file: File) => Promise<void>;
}> = ({ isOpen, onClose, onImport }) => {
  const [files, setFiles] = React.useState<File[]>([]);
  const [fileRejections, setFileRejections] = React.useState<FileRejection[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleChange = React.useCallback((acceptedFiles: File[]) => {
    const csvFiles = acceptedFiles.filter(
      (file) => file.type === 'text/csv' || file.name.endsWith('.csv')
    );
    if (csvFiles.length > 0) {
      setFiles([csvFiles[0]]);
    } else {
      alert('Please upload a valid CSV file.');
    }
  }, []);

  const handleRejected = React.useCallback((rejections: FileRejection[]) => {
    setFileRejections(rejections);
  }, []);

  const handleRemove = React.useCallback(() => {
    setFiles([]);
    setFileRejections([]);
  }, []);

  const handleImport = async () => {
    if (files.length > 0) {
      setIsSubmitting(true);
      await onImport(files[0]);
      setIsSubmitting(false);
      onClose(); // Close the dialog after submission
    }
  };

  const handleConfirm = async () => {
    await handleImport();
    handleRemove();
  };

  return (
    <>
      <Dialog
        isShown={isOpen}
        onCloseComplete={onClose}
        onConfirm={handleConfirm}
        confirmLabel="Save"
        isConfirmDisabled={files.length === 0 || isSubmitting}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        hasClose={!isSubmitting} // Hide the Close button during submission
      >
        {/* Disable content interaction when submitting */}
        <Pane pointerEvents={isSubmitting ? 'none' : 'auto'}>
          <FileUploader
            label="Upload File"
            description="You can upload 1 file. File can be up to 50 MB."
            maxSizeInBytes={50 * 1024 ** 2}
            maxFiles={1}
            onChange={handleChange}
            onRejected={handleRejected}
            renderFile={(file: File) => {
              const fileRejection = fileRejections.find(
                (fr) => fr.file.name === file.name
              );
              return (
                <FileCard
                  key={file.name}
                  isInvalid={fileRejection != null}
                  name={file.name}
                  onRemove={handleRemove}
                  sizeInBytes={file.size}
                  type={file.type}
                  validationMessage={
                    fileRejection ? fileRejection.message : undefined
                  }
                />
              );
            }}
            values={files}
          />
        </Pane>
      </Dialog>

      {/* Overlay with Loading spinner */}
      {isSubmitting && (
        <Portal>
          <Pane
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            background="rgba(0, 0, 0, 0.6)"
            zIndex={9999}
            pointerEvents="all" // Ensure overlay captures all pointer events
            onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()} // Prevent click events from propagating
          >
            <Pane
              background="white"
              padding={16}
              borderRadius={4}
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size={40} />
              <Heading size={600} marginTop={16}>
                Importing Accounts...
              </Heading>
            </Pane>
          </Pane>
        </Portal>
      )}
    </>
  );
};

// This is the save function that will be used to create a new account it needs a different endpoint call
const handleCreateSave = async (
  name: string,
  selectedGroup: string,
  country: Country
) => {
  // Remove any non-numeric characters from 'name'
  const numericName = name.replace(/\D/g, '');

  // Check if 'numericName' has exactly 10 digits
  if (numericName.length !== 10) {
    toaster.danger(
      'Account name must contain exactly 10 digits',
      { duration: 8 }
    );
    return;
  }

  const fullAccountID = `${country.dialCode}${numericName}`;

  try {
    await createNewAccount({
      accountId: fullAccountID,
      group: selectedGroup,
    });
    toaster.success('Account created successfully', { duration: 8 });
  } catch (error: any) {
    errorHandler(error);
    console.error('Error creating account', error);
    toaster.danger(error.detail, { duration: 10 });
  }
};


const ErrorDialog: React.FC <{
  isOpen: boolean;
  onClose: () => void;
  errorDetails: string[];
}> = ({isOpen, onClose, errorDetails}) => {
  return (
    <Dialog
      isShown={isOpen}
      title="Error"
      onCloseComplete={onClose}
      confirmLabel="OK"
    >
      <UnorderedList>
        {errorDetails.map((error, index) => (
          <ListItem key={index}>{error}</ListItem>
        ))}
      </UnorderedList>
    </Dialog>
  );
}

//This is the edit invite dialog that will be used to edit the invite text
const EditInviteDialog: React.FC <{
  isOpen: boolean;
  onClose: () => void;
}> = ({isOpen, onClose}) => {

  const [invite, setInvite] = useState<string>('');
  const [newInvite, setNewInvite] = useState<string>('');

  useEffect(() => {
    fetchInvite();
  }, []);

  const fetchInvite = async () => {
    try {
      const invite = await getInvite();
      setInvite(invite.invite);
    } catch (error) {
      console.error("Error fetching invite:", error);
    }
  };

  const handleDialogSave = async (newInvite: string) => {
    try {
      await updateInvite(newInvite);
      toaster.success("Invite updated successfully", { duration: 8 });
      onClose();
    } catch (error) {
      console.error("Error updating invite:", error);
      toaster.danger("Failed to update invite", { duration: 10 });
    }
  }

  return (
    <Pane>
      <Dialog
        isShown={isOpen}
        title="Edit Invitation"
        onCloseComplete={onClose}
        confirmLabel="Save"
        onConfirm={() => {
          handleDialogSave(newInvite);
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Pane>
            <TextareaField
            name='Invite Textarea'
            placeholder='Enter new invite text'
            value={newInvite || invite}
            onChange={(e:any) => setNewInvite(e.target.value)} />
          </Pane>
        </Suspense>
      </Dialog>
    </Pane>
  );
}

//This function will handle the logic of creating a new account
const CreateNewAccount:React.FC <{
  isOpen: boolean;
  onClose: () => void;
}> = ({isOpen, onClose}) => {
  const [groups, setGroups] = useState<GroupOption[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [country, setCountry] = useState<Country>({ countryCode: 'US', countryName: 'United States', dialCode: '+1', flag: '🇺🇸' });

  useEffect(() => {
    fetchGroups();
  }, [isOpen]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const fetchGroups = async () => {
    try {
      const groupNames = await getGroups();
      const formattedGroups = groupNames.map(group => ({ label: group, value: group }));
      setGroups(formattedGroups);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  return(
    <SideSheet
      isShown={isOpen}
      onCloseComplete={() => onClose()}
      preventBodyScrolling
    >
      <Pane padding={16} borderBottom="muted">
        <Heading size={600} marginBottom={majorScale(2)}>Create New Account</Heading>
      </Pane>
      {
        <React.Fragment>
          <Pane padding={16}>
            <Paragraph><strong>Account ID:</strong></Paragraph>
            <Pane style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
              <CountryPicker
                selectedCountry={country}
                onSelect={(selectedCountry) => {
                  setCountry(selectedCountry);
                }}
              />
              <TextInput
                name="account-id"
                placeholder="Enter Account ID"
                value={name}
                onChange={handleNameChange}/>
            </Pane>
          </Pane>
          <Pane padding={16}>
            <Paragraph><strong>Group:</strong></Paragraph>
            <Suspense fallback={<div>Loading...</div>}>
              <Combobox
                items={groups}
                itemToString={(item) => item ? item.label : ""}
                onChange={(selected) => setSelectedGroup(selected ? selected.value : "")}
                placeholder="Select group..."
              />
            </Suspense>
          </Pane>
          <Pane padding={16}>
            <button
              className="button-green button-padding"
              onClick={() => {
                handleCreateSave(name, selectedGroup, country)
              }}
            >
              Save
            </button>
            <button
              className="button-red button-padding"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </Pane>
        </React.Fragment>
      }
    </SideSheet>
  )
};

//This is the main function that will be used to display the account table

const AccountTable: React.FC = () => {
  const [accounts, setAccounts] = useState<RootAccount[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAccount, setSelectedAccount] = useState<RootAccount | null>(null);
  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);
  const [editedGroup, setEditedGroup] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<string>("All Groups");
  const [changeGroup, setChangeGroup] = useState<string>("All Groups");
  const [changeEditedGroup, setChangeEditedGroup] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [isNewAccountSheetOpen, setIsNewAccountSheetOpen] = useState(false);
  const [isEditInviteDialogOpen, setIsEditInviteDialogOpen] = useState(false);
  const [checkedState, setCheckedState] = useState<{ [key: string]: boolean }>({});
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string []>([]);
  const [isStatsDialogOpen, setIsStatsDialogOpen] = useState(false);
  const [statsData, setStatsData] = useState<any>(null);
  const accountsPerPage = 10;

  useEffect(() => {
    fetchAccounts();
    fetchGroups();
  }, []);

  //This logic will frtch the accounts from the API
  const fetchAccounts = async () => {
    try {
      const data = await getRootAccounts();
      const formattedData: RootAccount[] = data.map((item: any) => ({
        accountId: item.accountId,
        id: item.id,
        group: item.group,
        enabled: item.enabled,
        deviceOs: item.deviceOs || "N/A",
        coraV: item.coraV || "N/A",
        installed: item.installed || "N/A",
        status: item.status || "N/A",
      }));
      setAccounts(formattedData);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    } finally {
      setLoading(false);
    }
  };

  //This logic will fetch the groups from the API for the combobox when you select a group
  const fetchGroups = async () => {
    try {
      const groupNames = await getGroups();
      setGroups(groupNames);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value.toLowerCase());
    setCurrentPage(1);
  };

  const filteredAccounts = accounts.filter((account) =>
    account.accountId.toLowerCase().includes(searchQuery) &&
    (selectedGroup === "All Groups" || account.group === selectedGroup)
  );

  const indexOfLastAccount = currentPage * accountsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - accountsPerPage;
  const currentAccounts = filteredAccounts.slice(indexOfFirstAccount, indexOfLastAccount);

  const handleAccountIdClick = (account: RootAccount) => {
    setSelectedAccount(account);
    setEditedGroup(account.group);
    setIsSideSheetOpen(true);
  };

  //This is the handle save that will be used to update the account group
  const handleSave = async () => {
    if (selectedAccount) {
      try {
        if (!selectedAccount.id) {
          //format goes like this: accountId, bypassWifiTraffic, newGroup, roaming
          await updateProvAccount(selectedAccount.accountId, true, changeGroup, false);
        } else {
          await updateAccountGroup(selectedAccount.id, changeGroup, selectedAccount.enabled);
        }  
        setAccounts((prevAccounts) =>
          prevAccounts.map((account) =>
            account.accountId === selectedAccount.accountId
              ? { ...account, group: changeGroup, enabled: selectedAccount.enabled }
              : account
          )
        );
        setSelectedAccount({ ...selectedAccount, group: changeGroup, enabled: true});
        toaster.success("Account group updated successfully", { duration: 8 });
        // fetchAccounts();
        // fetchGroups();
        setIsSideSheetOpen(false);
      } catch (error) {
        console.error("Error updating account group:", error);
        toaster.danger("Failed to update account group", { duration: 10 });
      }
    }
  };

  //This is the logic that will be used to generate the CSV content for the template
  const generateCSVContent = () => {
    const csvContent = `Account Id,Group,Cellular Only,Roaming Only
8338675309,Normal,True,False
+18008675309,Control,False,True`;
    return csvContent;
  };

  const handleDownloadTemplate = () => {
    const csvContent = generateCSVContent();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Template.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  //This is the logic that will be used to import the accounts from the CSV file
  const handleImportAccounts = async (file: File) => {
    try {
      const result = await importAccounts(file);
      toaster.success("Accounts imported successfully", { duration: 8 });
      setIsImportDialogOpen(false);
      // Refresh the accounts list after successful import
      fetchAccounts();
      // fetchGroups();
    } catch (error: any) {
      const errorMessage = error.detail || error.message || "An unknown error occurred";
      setIsErrorDialogOpen(true);
      setErrorMessages(errorMessage);
    }
  };

  const handleCheckboxChange = (accountId: string, isChecked: boolean) => {
    setCheckedState(prevState => ({
      ...prevState,
      [accountId]: isChecked
    }));
  };

  //This is the logic that will be used to check if any of the checkboxes in the are checked
  const isAnyCheckboxChecked = () => {
    return Object.values(checkedState).some(checked => checked);
  };

  //This is the logic that will be used to get the checked account IDs
  const getCheckedAccountIds = () => {
    return Object.keys(checkedState).filter(accountId => checkedState[accountId]);
  };

  //This is the logic that will be used to prepare the accounts for resending the text
  const prepCheckAccounts = async () => {
    const checkedAccounts = getCheckedAccountIds();
    handleResendText(checkedAccounts);
  }

  const handleResendText = async (phoneNumbers: string[]) => {
    const data = {
      phoneNumbers: phoneNumbers,
    };
    try {
      await resendText(data);
      toaster.success("Text sent successfully", { duration: 8 });
    } catch (error) {
      console.error("Error resending text:", error);
      toaster.danger("Failed to resend text", { duration: 10 });
    }
    // return resendText(data);
  }

  const handleTimelineClick = () => {
    // Ensure accounts data is loaded
    if (accounts && accounts.length > 0) {
      setStatsData(accounts);
      setIsStatsDialogOpen(true);
    } else {
      toaster.warning('No accounts data available', { duration: 8 });
    }
  };
  

  if (loading) {
    return (
      <LoadingWord /> //This is the loading word that will be displayed while the accounts are loading
    );
  }

  return (
    <Pane>
      <Pane display="flex" justifyContent="space-between" marginBottom={16}>
        <Pane>
          <Button appearance="default" marginRight={8} onClick={()=> setIsNewAccountSheetOpen(true)}>Add Account</Button>
          <Button appearance="primary" marginRight={8} onClick={() => setIsImportDialogOpen(true)}>Import Accounts</Button>
          <Button appearance="default" marginRight={8} onClick={() => setIsEditInviteDialogOpen(true)}>Edit Invitation</Button>
          <Button appearance="default" marginRight={8} onClick={handleDownloadTemplate}>Download template</Button>
          <Button disabled = {!isAnyCheckboxChecked()} appearance="default" onClick={() => prepCheckAccounts()}>Resend Onboarding SMS</Button>
        </Pane>
        <Pane>
          <Button appearance="default" iconBefore={TimelineLineChartIcon} onClick={handleTimelineClick}>Stats</Button>
        </Pane>
      </Pane>

      <Table className="table">
        <Table.Head className="table-header">
          <Table.HeaderCell style={{ display: 'flex', paddingLeft: '16px'}}>
          <Checkbox
            checked={filteredAccounts.length > 0 && filteredAccounts.every(account => checkedState[account.accountId])}
            indeterminate={
              filteredAccounts.some(account => checkedState[account.accountId]) &&
              !filteredAccounts.every(account => checkedState[account.accountId])
            }
            onChange={(e) => {
              const isChecked = e.target.checked;
              const newCheckedState: { [key: string]: boolean } = {};

              filteredAccounts.forEach(account => {
                newCheckedState[account.accountId] = isChecked;
              });
            
              setCheckedState(prevState => ({
                ...prevState,
                ...newCheckedState
              }));
            }}
          />          
          
          <Table.SearchHeaderCell
            onChange={handleSearch}
            placeholder="Search accounts..."
          />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <SelectMenu
              title="Group"
              options={["All Groups", ...groups].map(group => ({ label: group, value: group }))}
              selected={selectedGroup}
              onSelect={(item) => setSelectedGroup(item.value as string)}
              position={Position.BOTTOM_RIGHT}
            >
              <Button>Group ▼</Button>
            </SelectMenu>
          </Table.HeaderCell>
          <Table.TextHeaderCell>Status</Table.TextHeaderCell>
          <Table.TextHeaderCell>Enabled</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {currentAccounts.map((account) => (
            <Table.Row className="table-row" key={account.accountId}>
              <Table.TextCell className="table-cell">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={checkedState[account.accountId] || false}
                    onChange={(e) => handleCheckboxChange(account.accountId, e.target.checked)}
                  />
                  <span
                    style={{ cursor: 'pointer', marginLeft: '8px' }}
                    onClick={() => handleAccountIdClick(account)}
                  >
                    {account.accountId}
                  </span>
                </div>
              </Table.TextCell>
              <Table.TextCell className="table-cell">{account.group}</Table.TextCell>
              <Table.TextCell className="table-cell">{account.status}</Table.TextCell>
              <Table.Cell className="table-cell">
                <Switch checked={account.enabled} disabled />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Pagination
        page={currentPage}
        totalPages={Math.ceil(filteredAccounts.length / accountsPerPage)}
        onPageChange={(page: number) => setCurrentPage(page)}
      />

      <SideSheet
        isShown={isSideSheetOpen}
        onCloseComplete={() => setIsSideSheetOpen(false)}
        preventBodyScrolling
      >
        {selectedAccount && (
          <Pane padding={16}>
            <Heading size={600} marginBottom={majorScale(2)}>Account Details</Heading>
            <Paragraph><strong>Account ID:</strong> {selectedAccount.accountId}</Paragraph>
            <Paragraph><strong>Device OS:</strong> {selectedAccount.deviceOs}</Paragraph>
            <Paragraph><strong>CORA Version:</strong> {selectedAccount.coraV}</Paragraph>
            <Paragraph><strong>Installed:</strong> {selectedAccount.installed}</Paragraph>
            <Paragraph><strong>Status:</strong> {selectedAccount.status}</Paragraph>
            <Pane marginY={majorScale(2)}>
            <Paragraph><strong>Group: 
              <Suspense fallback={<div>Loading...</div>}>
              <Combobox
                items={groups.map(group => ({ label: group, value: group }))}
                itemToString={(item) => item ? item.label : ""}
                onChange={(selected) => setChangeGroup(selected ? selected.value : "")}
                placeholder={selectedAccount.group || 'Select group...'}
              />
            </Suspense>
            </strong></Paragraph>
            </Pane>
            <Pane padding={16}>
              <button
                className="button-green button-padding"
                onClick={handleSave}
              >
              Save
              </button>
              <button
                className="button-red button-padding"
                onClick={() => setIsSideSheetOpen(false)}
              >
                Cancel
              </button>
            </Pane>
          </Pane>
        )}
      </SideSheet>

      <EditInviteDialog
        isOpen={isEditInviteDialogOpen}
        onClose={() => setIsEditInviteDialogOpen(false)}
      />

      <ErrorDialog
        isOpen={isErrorDialogOpen}
        onClose={() => setIsErrorDialogOpen(false)}
        errorDetails={errorMessages}
        />

      <FileUploaderSingleUpload
        isOpen={isImportDialogOpen}
        onClose={() => setIsImportDialogOpen(false)}
        onImport={handleImportAccounts}
      />
      <CreateNewAccount
        isOpen={isNewAccountSheetOpen}
        onClose={() => setIsNewAccountSheetOpen(false)}
      />

      <StatsDialog
        isOpen={isStatsDialogOpen}
        onClose={() => setIsStatsDialogOpen(false)}
        data={statsData}
      />
    </Pane>
  );
};

export default AccountTable;